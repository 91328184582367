import styled from '@emotion/styled'
import { useLenis } from '@studio-freight/react-lenis'
import { Brand } from 'app/components/Icons'
import { theme } from 'app/theme'
import { AnimatePresence, motion, useAnimate } from 'framer-motion'
import React, { memo, ReactNode, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

interface Props {
  children: ReactNode
  location: any
  pageContext: any
}

export const Transition = memo(function Transition({
  children,
  location,
  pageContext,
}: Props) {
  const bx = `linear-gradient(0deg, black 0% 1.45%, transparent 1.45% 3.33%, black 3.33% 4.59%, transparent 4.59% 6.66%, black 6.66% 7.72%, transparent 7.72% 10%, black 10% 10.85%, transparent 10.85% 13.33%, black 13.33% 13.99%, transparent 13.99% 16.66%, black 16.66% 17.12%, transparent 17.12% 20%, black 20% 20.25%, transparent 20.25% 23.33%, black 23.33% 23.39%, transparent 23.39% 26.66%, black 26.66% 26.66%, transparent 26.66% 30%, black 30% 30%, transparent 30% 33.33%, black 33.33% 33.33%, transparent 33.33% 36.66%, black 36.66% 36.66%, transparent 36.66% 40%, black 40% 40%, transparent 40% 43.33%, black 43.33% 43.33%, transparent 43.33% 46.66%, black 46.66% 46.66%, transparent 46.66% 50%, black 50% 50%, transparent 50% 53.33%, black 53.33% 53.33%, transparent 53.33% 56.66%, black 56.66% 56.66%, transparent 56.66% 60%, black 60% 60%, transparent 60% 63.33%, black 63.33% 63.33%, transparent 63.33% 66.66%, black 66.66% 66.66%, transparent 66.66% 70%, black 70% 70%, transparent 70% 73.33%, black 73.33% 73.33%, transparent 73.33% 76.66%, black 76.66% 76.66%, transparent 76.66% 80%, black 80% 80%, transparent 80% 83.33%, black 83.33% 83.33%, transparent 83.33% 86.66%, black 86.66% 86.66%, transparent 86.66% 90%, black 90% 90%, transparent 90% 93.33%, black 93.33% 93.33%, transparent 93.33% 96.66%, black 96.66% 96.66%, transparent 96.66% 100%)`
  const wx = `linear-gradient(0deg, black 0% 2.99%, transparent 2.99% 3.33%, black 3.33% 6.12%, transparent 6.12% 6.66%, black 6.66% 9.25%, transparent 9.25% 10%, black 10% 12.39%, transparent 12.39% 13.33%, black 13.33% 15.52%, transparent 15.52% 16.66%, black 16.66% 18.65%, transparent 18.65% 20%, black 20% 21.79%, transparent 21.79% 23.33%, black 23.33% 24.92%, transparent 24.92% 26.66%, black 26.66% 28.05%, transparent 28.05% 30%, black 30% 31.19%, transparent 31.19% 33.33%, black 33.33% 34.32%, transparent 34.32% 36.66%, black 36.66% 37.45%, transparent 37.45% 40%, black 40% 40.59%, transparent 40.59% 43.33%, black 43.33% 43.72%, transparent 43.72% 46.66%, black 46.66% 46.85%, transparent 46.85% 50%, black 50% 50%, transparent 50% 53.33%, black 53.33% 53.33%, transparent 53.33% 56.66%, black 56.66% 56.66%, transparent 56.66% 60%, black 60% 60%, transparent 60% 63.33%, black 63.33% 63.33%, transparent 63.33% 66.66%, black 66.66% 66.66%, transparent 66.66% 70%, black 70% 70%, transparent 70% 73.33%, black 73.33% 73.33%, transparent 73.33% 76.66%, black 76.66% 76.66%, transparent 76.66% 80%, black 80% 80%, transparent 80% 83.33%, black 83.33% 83.33%, transparent 83.33% 86.66%, black 86.66% 86.66%, transparent 86.66% 90%, black 90% 90%, transparent 90% 93.33%, black 93.33% 93.33%, transparent 93.33% 96.66%, black 96.66% 96.66%, transparent 96.66% 100%)`
  const xx = `linear-gradient(0deg, black 0% 3.33%, transparent 3.33% 3.33%, black 3.33% 6.66%, transparent 6.66% 6.66%, black 6.66% 10%, transparent 10% 10%, black 10% 13.33%, transparent 13.33% 13.33%, black 13.33% 16.66%, transparent 16.66% 16.66%, black 16.66% 20%, transparent 20% 20%, black 20% 23.32%, transparent 23.32% 23.33%, black 23.33% 26.46%, transparent 26.46% 26.66%, black 26.66% 29.59%, transparent 29.59% 30%, black 30% 32.72%, transparent 32.72% 33.33%, black 33.33% 35.86%, transparent 35.86% 36.66%, black 36.66% 38.99%, transparent 38.99% 40%, black 40% 42.12%, transparent 42.12% 43.33%, black 43.33% 45.26%, transparent 45.26% 46.66%, black 46.66% 48.39%, transparent 48.39% 50%, black 50% 51.52%, transparent 51.52% 53.33%, black 53.33% 54.66%, transparent 54.66% 56.66%, black 56.66% 57.79%, transparent 57.79% 60%, black 60% 60.92%, transparent 60.92% 63.33%, black 63.33% 64.06%, transparent 64.06% 66.66%, black 66.66% 67.19%, transparent 67.19% 70%, black 70% 70.32%, transparent 70.32% 73.33%, black 73.33% 73.46%, transparent 73.46% 76.66%, black 76.66% 76.66%, transparent 76.66% 80%, black 80% 80%, transparent 80% 83.33%, black 83.33% 83.33%, transparent 83.33% 86.66%, black 86.66% 86.66%, transparent 86.66% 90%, black 90% 90%, transparent 90% 93.33%, black 93.33% 93.33%, transparent 93.33% 96.66%, black 96.66% 96.66%, transparent 96.66% 100%)`
  const Sx = `linear-gradient(0deg, black 0% 3.33%, transparent 3.33% 3.33%, black 3.33% 6.66%, transparent 6.66% 6.66%, black 6.66% 10%, transparent 10% 10%, black 10% 13.33%, transparent 13.33% 13.33%, black 13.33% 16.66%, transparent 16.66% 16.66%, black 16.66% 20%, transparent 20% 20%, black 20% 23.33%, transparent 23.33% 23.33%, black 23.33% 26.66%, transparent 26.66% 26.66%, black 26.66% 30%, transparent 30% 30%, black 30% 33.33%, transparent 33.33% 33.33%, black 33.33% 36.66%, transparent 36.66% 36.66%, black 36.66% 40%, transparent 40% 40%, black 40% 43.33%, transparent 43.33% 43.33%, black 43.33% 46.66%, transparent 46.66% 46.66%, black 46.66% 49.92%, transparent 49.92% 50%, black 50% 53.06%, transparent 53.06% 53.33%, black 53.33% 56.19%, transparent 56.19% 56.66%, black 56.66% 59.32%, transparent 59.32% 60%, black 60% 62.46%, transparent 62.46% 63.33%, black 63.33% 65.59%, transparent 65.59% 66.66%, black 66.66% 68.72%, transparent 68.72% 70%, black 70% 71.86%, transparent 71.86% 73.33%, black 73.33% 74.99%, transparent 74.99% 76.66%, black 76.66% 78.12%, transparent 78.12% 80%, black 80% 81.26%, transparent 81.26% 83.33%, black 83.33% 84.39%, transparent 84.39% 86.66%, black 86.66% 87.52%, transparent 87.52% 90%, black 90% 90.66%, transparent 90.66% 93.33%, black 93.33% 93.79%, transparent 93.79% 96.66%, black 96.66% 96.92%, transparent 96.92% 100%)`
  const Cx = `linear-gradient(0deg, black 0% 3.33%, transparent 3.33% 3.33%, black 3.33% 6.66%, transparent 6.66% 6.66%, black 6.66% 10%, transparent 10% 10%, black 10% 13.33%, transparent 13.33% 13.33%, black 13.33% 16.66%, transparent 16.66% 16.66%, black 16.66% 20%, transparent 20% 20%, black 20% 23.33%, transparent 23.33% 23.33%, black 23.33% 26.66%, transparent 26.66% 26.66%, black 26.66% 30%, transparent 30% 30%, black 30% 33.33%, transparent 33.33% 33.33%, black 33.33% 36.66%, transparent 36.66% 36.66%, black 36.66% 40%, transparent 40% 40%, black 40% 43.33%, transparent 43.33% 43.33%, black 43.33% 46.66%, transparent 46.66% 46.66%, black 46.66% 50%, transparent 50% 50%, black 50% 53.33%, transparent 53.33% 53.33%, black 53.33% 56.66%, transparent 56.66% 56.66%, black 56.66% 60%, transparent 60% 60%, black 60% 63.33%, transparent 63.33% 63.33%, black 63.33% 66.66%, transparent 66.66% 66.66%, black 66.66% 70%, transparent 70% 70%, black 70% 73.33%, transparent 73.33% 73.33%, black 73.33% 76.53%, transparent 76.53% 76.66%, black 76.66% 79.66%, transparent 79.66% 80%, black 80% 82.79%, transparent 82.79% 83.33%, black 83.33% 85.93%, transparent 85.93% 86.66%, black 86.66% 89.06%, transparent 89.06% 90%, black 90% 92.19%, transparent 92.19% 93.33%, black 93.33% 95.33%, transparent 95.33% 96.66%, black 96.66% 98.46%, transparent 98.46% 100%)`
  const Tx = `linear-gradient(0deg, black 0% 3.33%, transparent 3.33% 3.33%, black 3.33% 6.66%, transparent 6.66% 6.66%, black 6.66% 10%, transparent 10% 10%, black 10% 13.33%, transparent 13.33% 13.33%, black 13.33% 16.66%, transparent 16.66% 16.66%, black 16.66% 20%, transparent 20% 20%, black 20% 23.33%, transparent 23.33% 23.33%, black 23.33% 26.66%, transparent 26.66% 26.66%, black 26.66% 30%, transparent 30% 30%, black 30% 33.33%, transparent 33.33% 33.33%, black 33.33% 36.66%, transparent 36.66% 36.66%, black 36.66% 40%, transparent 40% 40%, black 40% 43.33%, transparent 43.33% 43.33%, black 43.33% 46.66%, transparent 46.66% 46.66%, black 46.66% 50%, transparent 50% 50%, black 50% 53.33%, transparent 53.33% 53.33%, black 53.33% 56.66%, transparent 56.66% 56.66%, black 56.66% 60%, transparent 60% 60%, black 60% 63.33%, transparent 63.33% 63.33%, black 63.33% 66.66%, transparent 66.66% 66.66%, black 66.66% 70%, transparent 70% 70%, black 70% 73.33%, transparent 73.33% 73.33%, black 73.33% 76.66%, transparent 76.66% 76.66%, black 76.66% 80%, transparent 80% 80%, black 80% 83.33%, transparent 83.33% 83.33%, black 83.33% 86.66%, transparent 86.66% 86.66%, black 86.66% 90%, transparent 90% 90%, black 90% 93.33%, transparent 93.33% 93.33%, black 93.33% 96.66%, transparent 96.66% 96.66%, black 96.66% 100%, transparent 100% 100%)`
  const kx = `linear-gradient(0deg, black 0% 3.33%, transparent 3.33% 3.33%, black 3.33% 6.66%, transparent 6.66% 6.66%, black 6.66% 10%, transparent 10% 10%, black 10% 13.33%, transparent 13.33% 13.33%, black 13.33% 16.66%, transparent 16.66% 16.66%, black 16.66% 20%, transparent 20% 20%, black 20% 23.33%, transparent 23.33% 23.33%, black 23.33% 26.66%, transparent 26.66% 26.66%, black 26.66% 30%, transparent 30% 30%, black 30% 33.33%, transparent 33.33% 33.33%, black 33.33% 36.66%, transparent 36.66% 36.66%, black 36.66% 40%, transparent 40% 40%, black 40% 43.33%, transparent 43.33% 43.33%, black 43.33% 46.66%, transparent 46.66% 46.66%, black 46.66% 50%, transparent 50% 50%, black 50% 53.33%, transparent 53.33% 53.33%, black 53.33% 56.66%, transparent 56.66% 56.66%, black 56.66% 60%, transparent 60% 60%, black 60% 63.33%, transparent 63.33% 63.33%, black 63.33% 66.66%, transparent 66.66% 66.66%, black 66.66% 70%, transparent 70% 70%, black 70% 73.33%, transparent 73.33% 73.33%, black 73.33% 76.66%, transparent 76.66% 76.66%, black 76.66% 80%, transparent 80% 80%, black 80% 83.33%, transparent 83.33% 83.33%, black 83.33% 86.66%, transparent 86.66% 86.66%, black 86.66% 90%, transparent 90% 90%, black 90% 93.33%, transparent 93.33% 93.33%, black 93.33% 96.66%, transparent 96.66% 96.66%, black 96.66% 100%, transparent 100% 100%)`
  const nN = `linear-gradient(0deg, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%, black 0% 0%, transparent 0% 0%)`

  const [cookies, setCookie] = useCookies(['firstLoad'])

  const lenisInstance = useLenis()

  const [scope, animate] = useAnimate()
  const [loaded, setLoaded] = useState('')

  const duration = 0.08
  const ease = 'easeInOut'

  async function myAnimation() {
    await animate(scope.current, { maskImage: bx }, { duration, ease })
    await animate(scope.current, { maskImage: wx }, { duration, ease })
    await animate(scope.current, { maskImage: xx }, { duration, ease })
    await animate(scope.current, { maskImage: Sx }, { duration, ease })
    await animate(scope.current, { maskImage: Cx }, { duration, ease })
    await animate(scope.current, { maskImage: Tx }, { duration, ease })
    await animate(scope.current, { maskImage: kx }, { duration, ease })
    await animate(scope.current, { maskImage: Tx }, { duration, ease })
    await animate(scope.current, { maskImage: Cx }, { duration, ease })
    await animate(scope.current, { maskImage: Sx }, { duration, ease })
    await animate(scope.current, { maskImage: xx }, { duration, ease })
    await animate(scope.current, { maskImage: wx }, { duration, ease })
    await animate(scope.current, { maskImage: bx }, { duration, ease })
    await animate(scope.current, { maskImage: nN }, { duration, ease })
  }

  useEffect(() => {
    history.scrollRestoration = 'manual'

    setLoaded(location.pathname)

    if (loaded !== '' && cookies.firstLoad) {
      myAnimation()
    }

    window.addEventListener('CookiebotOnAccept', function (e) {
      //@ts-ignore
      if (Cookiebot.changed) {
        document.location.reload()
      }
    })

    return () => {
      setLoaded('')
    }
  }, [cookies, loaded, location])

  return (
    <AnimatePresence mode="wait">
      <Body aria-label="transition-body" role="complementary" />

      <Main
        key={location.pathname}
        role="main"
        style={pageContext.type === 'home' ? { overflow: 'hidden' } : {}}
      >
        <Blindes ref={scope} />

        <Background
          className={cookies.firstLoad ? 'hidden' : undefined}
          initial={{
            backgroundColor: theme.colors.variants.neutralLight2,
          }}
          animate={{
            backgroundColor: theme.colors.variants.primaryDark,
            transition: {
              delay: 0.4,
              duration: 1.4,
            },
          }}
          onAnimationStart={() => {
            lenisInstance?.stop()
          }}
          onAnimationComplete={() => {
            lenisInstance?.start()

            setCookie('firstLoad', true, {
              expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
            })
          }}
        >
          <Mask>
            <svg
              aria-label="partial-logo"
              xmlns="http://www.w3.org/2000/svg"
              width="113.095"
              height="58.666"
              viewBox="0 0 113.095 58.666"
            >
              <path
                d="M16258.426,1122.833a13.506,13.506,0,0,1,0-19.314,15.444,15.444,0,0,1,20.359,0,13.506,13.506,0,0,1,0,19.314,15.431,15.431,0,0,1-20.359,0Zm4.953-14.59a7.074,7.074,0,0,0-2.05,5.072,6.566,6.566,0,0,0,2.05,4.8,7.318,7.318,0,0,0,5.225,2.05,7.014,7.014,0,0,0,7.278-6.967,6.957,6.957,0,0,0-2.091-4.953,7.738,7.738,0,0,0-5.187-2.05A7.432,7.432,0,0,0,16263.379,1108.243Zm60.873,17.842v-25.813h6.7V1120.4H16339v5.688Zm-18.924,0v-25.813H16320v5.688h-7.971v4.3h7.549v5.687h-7.549v4.453H16320v5.688Zm-15.168,0v-20.125h-5.5v-5.687h17.689v5.688h-5.494v20.125Zm-46.787,0v-10.448h-9.678v10.448h-6.7v-25.817h6.7v9.64h9.678v-9.64h6.732v25.817Zm9.246-31.267-1.895-14.865-6.232,14.865h-2.67l-5.962-14.865-2.206,14.865H16227l4.375-25.817h6.619l5.224,13.778,5.536-13.778h6.7l3.866,25.817Zm63.768,0V1069h14.672v5.688h-7.976v4.3h7.55v5.692h-7.55v4.449h7.976v5.687Zm-34.4,0-1.587-4.334h-9.369l-1.739,4.334h-7.086l9.911-25.813h7.315l9.715,25.813Zm-9.095-9.443h5.725l-2.9-8.357Z"
                transform="translate(-16226.407 -1068.501)"
              />
            </svg>
            <svg
              aria-label="loaded-logo"
              className={loaded !== '' ? 'start' : undefined}
              xmlns="http://www.w3.org/2000/svg"
              width="22.955"
              height="26.816"
              viewBox="0 0 22.955 26.816"
            >
              <g transform="translate(-120.147 -27.22)">
                <path d="M132.152,27.72A8.341,8.341,0,0,0,125.842,30a8.5,8.5,0,0,0-2.206,5.806,7.449,7.449,0,0,0,2.051,5.379,7.23,7.23,0,0,0,3.56,1.781l-8.089,10.567h8.322l6.425-9.909v9.909h6.7V27.72Zm3.754,11.573h-1.277a5.031,5.031,0,0,1-3.251-1.045,3.214,3.214,0,0,1-.851-2.167,3.045,3.045,0,0,1,.89-2.168,4.546,4.546,0,0,1,3.213-1.006h1.277Z" />
              </g>
            </svg>
          </Mask>

          <Brand />
        </Background>

        {children}
      </Main>
    </AnimatePresence>
  )
})

const Body = styled.aside`
  width: 100vw;
  height: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
`

const Main = styled(motion.main)`
  width: 100vw;
`

const Blindes = styled(motion.div)`
  width: 100%;
  height: 100svh;
  background: ${theme.colors.variants.primaryLight};
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  will-change: mask-image;
`

const Mask = styled.div`
  width: 8.75rem;
  height: 4.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.colors.variants.primaryDark};
    &:last-of-type {
      height: 2.02rem;
      position: absolute;
      top: 0;
      left: 0;
      transform: scaleX(-1) translateX(-1.5rem);
      &.start {
        transform: translateX(1.4375rem);
        transition: 1s ease-in-out;
      }
    }
  }
`

const Background = styled(motion.div)`
  width: 100%;
  height: 100svh;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  &.hidden {
    opacity: 0;
    transition: 0.6s 2s;
    > svg {
      clip-path: inset(0);
      transition: 1s ease-in-out;
    }
  }

  > svg {
    width: 8.75rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralLight1};
    clip-path: inset(100% 0% 0% 0%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
`
